import {
  PagingSortingFilteringParams,
  filterOptionsSchema,
  ordersPagedSchema,
} from "../_types";
import { get } from "./gateway";

const URL = process.env.REACT_APP_API_ENDPOINT;

export async function getExternalPortalStatuses() {
  const url = `${URL}/customerPortal/getExternalPortalStatuses`;

  const response = await get(url);
  const parsedOptions = filterOptionsSchema.parse(response.data);

  return parsedOptions;
}

async function getCustomerOrders({
  url,
  params,
}: {
  url: string;
  params: PagingSortingFilteringParams;
}) {
  const response = await get(url, params);
  const parsedOrders = ordersPagedSchema.parse(response.data);

  return parsedOrders;
}

export async function getCustomerOrdersForAdmin({
  customerId,
  params,
}: {
  customerId: string;
  params: PagingSortingFilteringParams;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/ordersForAdmin`;
  return await getCustomerOrders({ url, params });
}

export async function getCustomerOrdersForSalesPerson({
  customerId,
  params,
}: {
  customerId: string;
  params: PagingSortingFilteringParams;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/ordersForSalesPerson`;
  return await getCustomerOrders({ url, params });
}

export async function getCustomerOrdersForContact({
  customerId,
  params,
}: {
  customerId: string;
  params: PagingSortingFilteringParams;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/ordersForContact`;
  return await getCustomerOrders({ url, params });
}
