import { useSearchParams } from "react-router-dom";
import { SortingState, Updater } from "@tanstack/react-table";
import { sortingParamsSchema } from "../_types";

export function useQueryStringSingleSort() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getSortingState = (): SortingState => {
    const { sort, sortDirection } = sortingParamsSchema.parse(
      Object.fromEntries(searchParams.entries())
    );

    if (sort) {
      return [
        {
          id: sort,
          desc: sortDirection === "desc",
        },
      ];
    }

    return [];
  };

  const setSortingState = (newSortingState: SortingState): void => {
    if (newSortingState.length === 0) {
      searchParams.delete("sort");
      searchParams.delete("sortDirection");
      setSearchParams({ ...Object.fromEntries(searchParams) });
    } else {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        sort: newSortingState[0].id,
        sortDirection: newSortingState[0].desc ? "desc" : "asc",
      });
    }
  };

  const onSortingChange = (updater: Updater<SortingState>): void => {
    const newSortingState =
      typeof updater === "function" ? updater(getSortingState()) : updater;
    setSortingState(newSortingState);
  };

  return {
    onSortingChange,
    getSortingState,
    setSortingState,
  };
}
