import { z } from "zod";

export const dropdownItemSchema = z.object({
  id: z.coerce.number(),
  name: z.string(),
});

export type DropdownItem = z.infer<typeof dropdownItemSchema>;

export const dropdownItemsSchema = dropdownItemSchema.array();

export type DropdownItems = z.infer<typeof dropdownItemsSchema>;
