import { Form } from "react-bootstrap";
import { Field as FinalField } from "react-final-form";

export interface CheckboxProps {
  name: string;
  label: string;
  formGroupClassName?: string;
  labelClassName?: string;
}

export function Checkbox({ name, label, formGroupClassName }: CheckboxProps) {
  return (
    <FinalField
      name={name}
      type="checkbox"
      render={({ input, meta }) => (
        <Form.Group className={formGroupClassName} controlId={name}>
          <Form.Check
            {...input}
            type="checkbox"
            label={label}
            isInvalid={meta.error}
          />
          {meta.touched && meta.error && (
            <Form.Control.Feedback type="invalid">
              {meta.error}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      )}
    />
  );
}
