import * as React from "react";

export interface DebouncedInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange: (value: string | number | readonly string[] | undefined) => void;
  debounceWait?: number;
}

// A debounced input react component
export function DebouncedInput({
  value: initialValue,
  onChange,
  debounceWait = 500,
  ...props
}: DebouncedInputProps) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    if (initialValue === value) {
      // Because this is a janky debounce, this ensures it doesn't trigger
      // itself on initial rendering, thereby making extra network calls.
      // I hate this, you probably hate this, I'd love to make it better.
      return;
    }

    const timeout = setTimeout(() => {
      onChange(value);
    }, debounceWait);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
