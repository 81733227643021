import {
  jobDueDateDetailsSchema,
  jobDueDateMessagesSchema,
  jobDueDatePackingListsSchema,
  jobDueDatePartStatusesSchema,
  jobDueDateMessageDropdownOptionsSchema,
} from "../_types";
import { get, post, put } from "./gateway";

const URL = process.env.REACT_APP_API_ENDPOINT;

export async function getJobDueDateDetails({
  customerId,
  orderId,
  jobDueDateId,
}: {
  customerId: string;
  orderId: string;
  jobDueDateId: string;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/details`;
  const response = await get(url);
  const parsedJobDueDateDetails = jobDueDateDetailsSchema.parse(response.data);

  return parsedJobDueDateDetails;
}

export async function getJobDueDateShippingDetails({
  customerId,
  orderId,
  jobDueDateId,
}: {
  customerId: string;
  orderId: string;
  jobDueDateId: string;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/shippingDetails`;

  const response = await get(url);
  const parsedJobDueDatePackingLists = jobDueDatePackingListsSchema.parse(
    response.data
  );

  return parsedJobDueDatePackingLists;
}

export async function getPreselectedMessages() {
  const url = `${URL}/customerPortal/preselectedMessages`;

  const response = await get(url);
  const parsedPreselectedMessages =
    jobDueDateMessageDropdownOptionsSchema.parse(response.data);

  return parsedPreselectedMessages;
}

export async function getMessages({
  customerId,
  orderId,
  jobDueDateId,
}: {
  customerId: string;
  orderId: string;
  jobDueDateId: string;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/messages`;

  const response = await get(url);
  const parsedJobDueDateMessages = jobDueDateMessagesSchema.parse(
    response.data
  );

  return parsedJobDueDateMessages;
}

export async function sendFreeformMessage({
  customerId,
  orderId,
  jobDueDateId,
  message,
}: {
  customerId: string;
  orderId: string;
  jobDueDateId: string;
  message: string;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/freeformMessage`;

  const response = await post({ url, body: { message } });

  return response;
}

export async function sendPreselectedMessage({
  customerId,
  orderId,
  jobDueDateId,
  messageId,
}: {
  customerId: string;
  orderId: string;
  jobDueDateId: string;
  messageId: number;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/preselectedMessage`;

  const response = await post({ url, body: { messageId } });

  return response;
}

export async function updateAskDateForContact({
  customerId,
  orderId,
  jobDueDateId,
  askDate,
}: {
  customerId: string;
  orderId: string;
  jobDueDateId: string;
  askDate: string | null;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/updateAskDate`;

  const response = await put({ url, body: { askDate } });

  return response;
}

export async function getJobDueDatePartStatuses({
  customerId,
  orderId,
  jobDueDateId,
}: {
  customerId: string;
  orderId: number;
  jobDueDateId: number;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/jobDueDatePartStatuses`;

  const response = await get(url);
  const parsedJobDueDatePartStatuses = jobDueDatePartStatusesSchema.parse(
    response.data
  );

  return parsedJobDueDatePartStatuses;
}
