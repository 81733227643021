import { Form as BSForm, Button, Row } from "react-bootstrap";
import { Form as FinalForm, Field as FinalField } from "react-final-form";
import { Send } from "react-bootstrap-icons";
import { Form } from "react-router-dom";
import {
  JobDueDateMessageDropdownOption,
  JobDueDateMessageDropdownOptions,
} from "../../_types";

export interface PreselectedJobDueDateMessageDropdownProps {
  preselectedMessages: JobDueDateMessageDropdownOptions;
  handleOnSubmit: (values: Record<string, any>) => Promise<void>;
  serverError: string[] | undefined;
}

export function PreselectedJobDueDateMessageDropdown({
  preselectedMessages,
  handleOnSubmit,
  serverError,
}: PreselectedJobDueDateMessageDropdownProps) {
  return (
    <FinalForm
      onSubmit={(values, form) => {
        handleOnSubmit(values);
        form.reset();
      }}
    >
      {({ form, handleSubmit, submitting, pristine }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <FinalField
              name="messageId"
              render={({ input, meta }) => (
                <BSForm.Group controlId="messageId">
                  <BSForm.Label>Message</BSForm.Label>

                  <BSForm.Select
                    {...input}
                    onChange={(e) => input.onChange(e.target.value)}
                    isInvalid={meta.error || serverError}
                  >
                    <option value="" key={0}>
                      {`Select a message`}
                    </option>
                    {preselectedMessages.map(
                      (m: JobDueDateMessageDropdownOption) => (
                        <option
                          value={m.customerPortalMessageId}
                          key={m.customerPortalMessageId}
                        >
                          {m.message}
                        </option>
                      )
                    )}
                  </BSForm.Select>

                  {((meta.touched && meta.error) || serverError) && (
                    <BSForm.Control.Feedback type="invalid">
                      {meta.error ?? serverError}
                    </BSForm.Control.Feedback>
                  )}
                </BSForm.Group>
              )}
            />
          </Row>
          <Button
            className="mb-3"
            type="button"
            variant="secondary"
            onClick={() => form.reset()}
            disabled={submitting || pristine}
          >
            Cancel
          </Button>{" "}
          <Button
            className="mb-3"
            type="submit"
            variant="primary"
            disabled={submitting || pristine}
          >
            {"Send "} <Send />
          </Button>
        </Form>
      )}
    </FinalForm>
  );
}
