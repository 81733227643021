import { QBIC_APP_URL } from "../../../../../../constants";

function getPackingListUrl(packingListId: number) {
  return `${QBIC_APP_URL}/shipping/${packingListId}`;
}

export const PackingListId = ({ id }: { id: number }) => {
  return (
    <a href={`${getPackingListUrl(id)}`} target="_blank" rel="noreferrer">
      {id}
    </a>
  );
};
