import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const LogoutPage = () => {
  return (
    <Container fluid className="pt-3 pb-3">
      <Row>
        <Col>You have been successfully logged out!</Col>
      </Row>
    </Container>
  );
};
