import { useSearchParams } from "react-router-dom";
import { PaginationState, Updater } from "@tanstack/react-table";
import { pagingParamsSchema } from "../_types";

export function useQueryStringPagination() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getPaginationState = (): PaginationState => {
    const params = pagingParamsSchema.parse(Object.fromEntries(searchParams));
    return {
      pageIndex: params.page - 1,
      pageSize: params.pageSize,
    };
  };

  const setPaginationState = (newPaginationState: PaginationState): void => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (newPaginationState.pageIndex + 1).toString(),
      pageSize: newPaginationState.pageSize.toString(),
    });
  };

  const onPaginationChange = (updater: Updater<PaginationState>): void => {
    const newPaginationState =
      typeof updater === "function" ? updater(getPaginationState()) : updater;
    setPaginationState(newPaginationState);
  };

  return {
    onPaginationChange,
    getPaginationState,
    setPaginationState,
  };
}
