import React from "react";
import {
  ImageMagnifier,
  ImageMagnifierProps,
} from "./ImageMagnifier/ImageMagnifier";
import { convertAcuuPrecImageUrlResolution } from "../_helpers";
import noImage from "../_images/no_image.png";

/**
 * Inherits from `ImageMagnifierProps` but replaces the type on `highRezImageUrl`
 * and `lowRezImageUrl` to be `string | null | undefined` to match incoming types
 */
export declare interface PartImageMagnifierProps
  extends Omit<ImageMagnifierProps, "highRezImageUrl" | "lowRezImageUrl"> {
  highRezImageUrl?: string | null;
  lowRezImageUrl?: string | null;
}

/**
 * A wrapper component around `ImageMagnifier`. It performs additional logic for image URLs before calling
 * the `ImageMagnifier` with cleaned-up image URLs.
 */
export const PartImageMagnifier = ({
  alt,
  fallback = noImage,
  highRezImageUrl,
  lowRezImageUrl,
  smallImageHeight,
  zoomedImageMaxHeight,
  zoomedImageMaxWidth,
}: PartImageMagnifierProps) => {
  const smallImageSrc = React.useMemo(() => {
    if (lowRezImageUrl) return lowRezImageUrl;
    else if (highRezImageUrl) {
      return convertAcuuPrecImageUrlResolution(highRezImageUrl);
    } else return fallback;
  }, [fallback, highRezImageUrl, lowRezImageUrl]);

  const zoomedImageSrc = React.useMemo<string>((): string => {
    if (highRezImageUrl) return highRezImageUrl;
    else if (lowRezImageUrl) {
      return convertAcuuPrecImageUrlResolution(lowRezImageUrl);
    } else return fallback;
  }, [fallback, highRezImageUrl, lowRezImageUrl]);

  return (
    <ImageMagnifier
      alt={alt}
      fallback={fallback}
      highRezImageUrl={zoomedImageSrc}
      lowRezImageUrl={smallImageSrc}
      smallImageHeight={smallImageHeight}
      zoomedImageMaxHeight={zoomedImageMaxHeight}
      zoomedImageMaxWidth={zoomedImageMaxWidth}
    />
  );
};
