import { z } from "zod";

export const customerSchema = z.object({
  id: z.number(),
  name: z.string(),
});
export type Customer = z.infer<typeof customerSchema>;

export const customersSchema = customerSchema.array();
export type Customers = z.infer<typeof customersSchema>;

export const subscriptionStatusSchema = z.object({
  isSubscribed: z.boolean(),
});
export type SubscriptionStatus = z.infer<typeof subscriptionStatusSchema>;
