import {
  ActionFunction,
  LoaderFunction,
  useActionData,
  useLoaderData,
  useParams,
  useSubmit,
} from "react-router-dom";
import {
  JobDueDateMessage,
  JobDueDateMessages,
  JobDueDateMessageDropdownOptions,
  SendJobDueDateMessageErrors,
  sendJobDueDateFreeformMessageSchema,
  sendJobDueDatePreselectedMessageSchema,
} from "../../../../../../_types";
import {
  nameofFactory,
  UserRole,
  getUserRole,
} from "../../../../../../_helpers";
import {
  getMessages,
  getPreselectedMessages,
  sendFreeformMessage,
  sendPreselectedMessage,
} from "../../../../../../_gateways/jobDueDateGateways";
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
} from "@tanstack/table-core";
import React from "react";
import { useReactTable } from "@tanstack/react-table";
import { BootstrapTable } from "../../../../../../_components";
import {
  FreeformJobDueDateMessageInput,
  PreselectedJobDueDateMessageDropdown,
} from "../../../../../../_components/JobDueDateMessaging";

export const jobDueDateMessagesLoader: LoaderFunction = async ({ params }) => {
  const userRole = await getUserRole();

  const { customerId, orderId, jobDueDateId } = params;
  if (!customerId || !orderId || !jobDueDateId)
    throw new Error("Cannot fetch data due to bad route.");

  switch (userRole as UserRole) {
    case UserRole.Admin:
    case UserRole.SalesPerson:
      return {
        data: await getMessages({
          customerId,
          orderId,
          jobDueDateId,
        }),
        preSelectedMessages: await getPreselectedMessages(),
      };
    case UserRole.Contact:
      return {
        data: await getMessages({
          customerId,
          orderId,
          jobDueDateId,
        }),
        preSelectedMessages: null,
      };
    default:
      throw new Error("Unauthorized.");
  }
};

export const sendMessage: ActionFunction = async ({ params, request }) => {
  const userRole = await getUserRole();

  const { customerId, orderId, jobDueDateId } = params;
  if (!customerId || !orderId || !jobDueDateId)
    throw new Error("Cannot send message due to bad route. Please try again.");

  switch (userRole as UserRole) {
    case UserRole.Admin:
    case UserRole.SalesPerson:
      const parsedPreselectedMessageObject =
        sendJobDueDatePreselectedMessageSchema.parse(
          Object.fromEntries(await request.formData())
        );
      return await sendPreselectedMessage({
        customerId,
        orderId,
        jobDueDateId,
        messageId: parsedPreselectedMessageObject.messageId,
      });
    case UserRole.Contact:
      const parsedFreeformMessageObject =
        sendJobDueDateFreeformMessageSchema.parse(
          Object.fromEntries(await request.formData())
        );
      return await sendFreeformMessage({
        customerId,
        orderId,
        jobDueDateId,
        message: parsedFreeformMessageObject.message,
      });
    default:
      throw new Error("Unauthorized.");
  }
};

export function JobDueDateMessagingPage() {
  const { customerId, orderId, jobDueDateId } = useParams();
  const nameof = nameofFactory<JobDueDateMessage>();
  const submitMessage = useSubmit();
  const submitMessageErrors = useActionData() as SendJobDueDateMessageErrors;
  const { data, preSelectedMessages } = useLoaderData() as {
    data: JobDueDateMessages;
    preSelectedMessages: JobDueDateMessageDropdownOptions | null;
  };

  const handleOnSubmit = async (values: Record<string, any>) => {
    submitMessage(values, {
      method: "post",
      action: `/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/messaging`,
    });
  };

  const columnHelper = createColumnHelper<JobDueDateMessage>();
  const columns = React.useMemo(() => {
    var generatedColumns = [
      columnHelper.accessor((row: JobDueDateMessage) => row.senderName, {
        header: "Sender",
        id: nameof("senderName"),
      }),
      columnHelper.accessor((row: JobDueDateMessage) => row.message, {
        header: "Message",
        id: nameof("message"),
      }),
      columnHelper.accessor((row: JobDueDateMessage) => row.sentTime, {
        header: "Date",
        id: nameof("sentTime"),
      }),
    ];

    return generatedColumns;
  }, [columnHelper, nameof]);

  const options: TableOptions<JobDueDateMessage> = {
    columns,
    data: data,
    enableFilters: false,
    getCoreRowModel: getCoreRowModel(),
  };
  const table = useReactTable(options);

  return (
    <>
      {preSelectedMessages ? (
        <PreselectedJobDueDateMessageDropdown
          preselectedMessages={preSelectedMessages}
          handleOnSubmit={handleOnSubmit}
          serverError={submitMessageErrors?.errors?.Message}
        />
      ) : (
        <FreeformJobDueDateMessageInput
          handleOnSubmit={handleOnSubmit}
          serverError={submitMessageErrors?.errors?.Message}
        />
      )}

      {data.length === 0 ? (
        <div>No message history.</div>
      ) : (
        <BootstrapTable table={table} />
      )}
    </>
  );
}
