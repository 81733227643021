import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row } from "react-bootstrap";
import { Header } from "../_components/Header";
import { Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    oauth: {
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      responseType: "code",
    },
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_REGION,
    // // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: process.env.REACT_APP_POOL_CLIENT_ID,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: process.env.REACT_APP_DOMAIN,
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the
      //  cookie transmission requires a secure protocol (https).
      secure: process.env.REACT_APP_SECURE, // set false if local
    },
    storage: window.localStorage,
  },
});

export const AppLayout = () => {
  return (
    <Container fluid style={{ background: "#F2F3F3" }}>
      <Row>
        <Header />
      </Row>
      <Row className="mt-5 p-3 pt-5">
        <Outlet />
      </Row>
    </Container>
  );
};
