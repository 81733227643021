import { Column } from "@tanstack/react-table";
import { DebouncedInput } from "../DebouncedInput";

export function TextFilter<T>({
  getFilterValue,
  setFilterValue,
  columnDef,
}: Column<T, unknown>) {
  return (
    <DebouncedInput
      type="text"
      value={(getFilterValue() ?? "") as string}
      onChange={(value) => setFilterValue(value)}
      placeholder={`Filter ${columnDef.header}`}
    />
  );
}
