export declare interface TrackingNumberCellProps {
  trackingNumbers: string[];
}

export const TrackingNumberCell = ({
  trackingNumbers,
}: TrackingNumberCellProps) => {
  const internalTrackingNumbers = ["accu truck", "accu-truck", "truck"];
  const trackingNumberIsInternal = (tn: string) =>
    internalTrackingNumbers.includes(tn.toLowerCase());

  return (
    <div>
      {trackingNumbers.map((tn) => (
        <span>
          {trackingNumberIsInternal(tn) ? (
            <span>{tn}</span>
          ) : (
            <a
              href={`https://www.google.com/search?q=${tn}`}
              target="_blank"
              rel="noreferrer"
            >
              {tn}
            </a>
          )}
          <br />
        </span>
      ))}
    </div>
  );
};
