import * as React from "react";
import Pagination from "react-bootstrap/Pagination";
import { Table } from "@tanstack/react-table";
import { Form, Col, Row } from "react-bootstrap";

interface CustomPaginationProps {
  currentPage: number;
  totalPages: number;
  onChange: (pageNumber: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onNext: () => void;
  onPrevious: () => void;
  pageSize: number;
}

export const getPageNumbersToDisplay = ({
  totalPages,
  currentPage,
}: {
  totalPages: number;
  currentPage: number;
}): { firstPage: number; lastPage: number } => {
  if (totalPages === 0) {
    return { firstPage: 1, lastPage: 1 };
  }
  const maxPagesToShow = 5;
  const pagesToShow = Math.min(totalPages, maxPagesToShow);

  let firstDisplayPage = currentPage > 3 ? currentPage - 2 : 1;

  const maxStartPage = Math.max(totalPages + 1 - pagesToShow, 1);
  if (firstDisplayPage > maxStartPage) firstDisplayPage = maxStartPage;

  let lastDisplayPage = firstDisplayPage + pagesToShow - 1;
  if (lastDisplayPage > totalPages) lastDisplayPage = totalPages;

  return { firstPage: firstDisplayPage, lastPage: lastDisplayPage };
};

export function CustomPagination({
  currentPage,
  onChange,
  totalPages,
  onNext,
  onPrevious,
  onChangePageSize,
  pageSize,
}: CustomPaginationProps) {
  const { firstPage, lastPage } = React.useMemo(
    () =>
      getPageNumbersToDisplay({
        totalPages,
        currentPage,
      }),
    [currentPage, totalPages]
  );

  const pageNumbers = React.useMemo(
    () =>
      Array.from({ length: lastPage - firstPage + 1 }, (k, v) => firstPage + v),
    [firstPage, lastPage]
  );

  const showLeftEllipsis = firstPage > 1;
  const showRightEllipsis = lastPage < totalPages;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Row>
        <Col>
          <Form.Group as={Row} controlId="entriesPerPage">
            <Form.Label column="sm" sm={7}>
              Entries Per Page:
            </Form.Label>
            <Col sm={5}>
              <Form.Select
                onChange={(e) => onChangePageSize(parseInt(e.target.value, 10))}
                value={pageSize}
              >
                <option>10</option>
                <option>15</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </Form.Select>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination>
            <Pagination.First
              disabled={currentPage === 1}
              onClick={() => onChange(1)}
            />
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={onPrevious}
            />
            {showLeftEllipsis ? <Pagination.Ellipsis disabled /> : null}
            {pageNumbers.map((n) => (
              <Pagination.Item
                key={n}
                active={n === currentPage}
                onClick={() => onChange(n)}
              >
                {n}
              </Pagination.Item>
            ))}
            {showRightEllipsis ? <Pagination.Ellipsis disabled /> : null}
            <Pagination.Next
              disabled={currentPage >= totalPages}
              onClick={onNext}
            />
            <Pagination.Last
              disabled={currentPage >= totalPages}
              onClick={() => onChange(totalPages)}
            />
          </Pagination>
        </Col>
      </Row>
    </div>
  );
}

interface CustomTablePaginationProps<T> {
  table: Table<T>;
}

export function CustomTablePagination<T>({
  table,
}: CustomTablePaginationProps<T>) {
  return (
    <CustomPagination
      currentPage={table.getState().pagination.pageIndex + 1}
      totalPages={table.getPageCount()}
      onChange={(pageNumber) => table.setPageIndex(pageNumber - 1)}
      onNext={table.nextPage}
      onPrevious={table.previousPage}
      onChangePageSize={(pageSize) => table.setPageSize(pageSize)}
      pageSize={table.getState().pagination.pageSize}
    />
  );
}
