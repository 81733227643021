import { customersSchema, subscriptionStatusSchema } from "../_types";
import { deleteItem, get, post } from "./gateway";

const URL = process.env.REACT_APP_API_ENDPOINT;

async function getCustomers(url: string) {
  const response = await get(url);
  const parsedCustomers = customersSchema.parse(response.data);

  return parsedCustomers;
}

export async function getCustomersForAdmin() {
  const url = `${URL}/customerPortal/getCustomersForAdmin`;
  return await getCustomers(url);
}

export async function getCustomersForSalesPerson() {
  const url = `${URL}/customerPortal/getCustomersForSalesPerson`;
  return await getCustomers(url);
}

export async function getCustomersForContact() {
  const url = `${URL}/customerPortal/getCustomersForContact`;
  return await getCustomers(url);
}

export async function getSubscriptionStatus({
  customerId,
}: {
  customerId: string;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/subscription`;

  const response = await get(url);
  const parsedSubscriptionStatus = subscriptionStatusSchema.parse(
    response.data
  );

  return parsedSubscriptionStatus;
}

export async function subscribeToCustomer({
  customerId,
}: {
  customerId: string;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/subscription`;

  const response = await post({ url, body: {} });

  return response;
}

export async function unsubscribeFromCustomer({
  customerId,
}: {
  customerId: string;
}) {
  const url = `${URL}/customerPortal/customer/${customerId}/subscription`;

  const response = await deleteItem(url);

  return response;
}
