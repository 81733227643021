import { Form as BSForm } from "react-bootstrap";
import { Form } from "react-router-dom";
import { Form as FinalForm, Field as FinalField } from "react-final-form";

export interface SubscriptionFormProps {
  formId: string;
  isSubscribed: boolean;
  handleOnSubmit: (values: Record<string, any>) => Promise<void>;
}

export const SubscriptionForm = ({
  formId,
  isSubscribed,
  handleOnSubmit,
}: SubscriptionFormProps) => {
  return (
    <>
      <FinalForm
        onSubmit={handleOnSubmit}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} id={formId}>
            <FinalField
              name="subscribe"
              type="radio"
              render={({ input }) => (
                <BSForm.Group controlId="subscribeToCustomerFormGroup">
                  <BSForm.Check
                    type="radio"
                    inline
                    label="Subscribe"
                    name="subscribeRadioOption"
                    disabled={isSubscribed}
                    onChange={input.onChange}
                  />
                </BSForm.Group>
              )}
            />
            <FinalField
              name="unsubscribe"
              type="radio"
              render={({ input }) => (
                <BSForm.Group controlId="unsubscribeToCustomerFormGroup">
                  <BSForm.Check
                    type="radio"
                    inline
                    label="Unsubscribe"
                    name="subscribeRadioOption"
                    disabled={!isSubscribed}
                    onChange={input.onChange}
                  />
                </BSForm.Group>
              )}
            />
          </Form>
        )}
      />
    </>
  );
};
