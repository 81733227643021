import { ZodSchema } from "zod";
import {
  FilteringParams,
  OrderFilterParams,
  orderFilterParamsSchema,
  PagingParams,
  pagingParamsSchema,
  SortingParams,
  sortingParamsSchema,
} from "../_types";

export function parseQuerystringParams<T>(
  request: Request,
  schema: ZodSchema
): T {
  const url = new URL(request.url);
  const urlSearchParamsEntries = url.searchParams.entries();
  const urlSearchParams = Object.fromEntries(urlSearchParamsEntries);

  return schema.parse(urlSearchParams);
}

export function getPagingParams(request: Request): PagingParams {
  const url = new URL(request.url);
  const pagingParams = pagingParamsSchema.parse(
    Object.fromEntries(url.searchParams)
  );

  return pagingParams;
}

export function getSortingParams(request: Request): SortingParams {
  const url = new URL(request.url);
  const sortingParams = sortingParamsSchema.parse(
    Object.fromEntries(url.searchParams)
  );

  return sortingParams;
}

export function getFilteringParams(request: Request): FilteringParams {
  const { searchParams } = new URL(request.url);
  // Populate two lists with the current filterFields and filterValues searchParams
  // Unable to zod parse duplicate keys with distinct values
  const filterFields = searchParams.getAll("filterFields");
  const filterValues = searchParams.getAll("filterValues");

  const filterParams: FilteringParams = {
    filterFields,
    filterValues,
  };

  return filterParams;
}

export function getOrderFilterParams(request: Request): OrderFilterParams {
  const url = new URL(request.url);
  const orderFilterParams = orderFilterParamsSchema.parse(
    Object.fromEntries(url.searchParams)
  );

  return orderFilterParams;
}
