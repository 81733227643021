/* eslint-disable react/jsx-props-no-spreading */

import { Form } from "react-bootstrap";
import { DropdownItems } from "../../_types";
import { FormFieldWithLabel } from "./FormFieldWithLabel";

interface FormSelectProps {
  name: string;
  label: string;
  options: DropdownItems;
  serverError: string | string[] | undefined;
  onChange?: (event: React.ChangeEvent<HTMLElement>) => void;
  disabled?: boolean;
}

export function FormSelect({
  name,
  label,
  options,
  serverError,
  onChange = () => {},
  disabled = false,
}: FormSelectProps) {
  return (
    <FormFieldWithLabel name={name} label={label} serverError={serverError}>
      {(input) => (
        <Form.Select
          {...input}
          disabled={disabled}
          onChange={(e) => {
            // Call the internal component onChange so it can handle it's internal business
            input.onChange(e);
            // If we passed in an onChange, call it to do what we need outside the component
            if (onChange) onChange(e);
          }}
        >
          {options.map((c: { id: number; name: string }) => (
            <option value={c.id} key={c.id}>
              {c.name}
            </option>
          ))}
        </Form.Select>
      )}
    </FormFieldWithLabel>
  );
}
