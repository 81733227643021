import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { GearFill } from "react-bootstrap-icons";
import { Form, useSearchParams } from "react-router-dom";
import { Checkbox } from "../../_components/_finalFormSpecific/Checkbox";
import { Form as FinalForm } from "react-final-form";

export const CustomerOrderFilterModal = () => {
  const [show, setShow] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnSubmit = async (values: Record<string, any>) => {
    const { includeClosed } = values;

    if (includeClosed) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        includeClosed,
      });
    } else {
      searchParams.delete("includeClosed");
      setSearchParams(searchParams);
    }

    handleClose();
  };

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        <GearFill />
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Filter Options</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FinalForm
            initialValues={{ includeClosed: searchParams.get("includeClosed") }}
            onSubmit={handleOnSubmit}
            render={({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} id="orderDataFilterForm">
                <Row className="mb-3">
                  <Checkbox
                    name="includeClosed"
                    label="Show closed items"
                    formGroupClassName="mb-2"
                  />
                </Row>
              </Form>
            )}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" form="orderDataFilterForm">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
