/* eslint-disable react/jsx-props-no-spreading */

import { Form } from "react-bootstrap";
import { Field, FieldInputProps } from "react-final-form";
import { FormFieldWithErrorHandling } from "../FormFieldWithErrorHandling";

interface FormFieldWithLabelProps {
  name: string;
  label: string;
  serverError: string | string[] | undefined;
  children: (input: FieldInputProps<any, HTMLElement>) => React.ReactElement;
}

export function FormFieldWithLabel({
  name,
  label,
  serverError,
  children,
}: FormFieldWithLabelProps) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Field
        name={name}
        render={({ input, meta }) => (
          <FormFieldWithErrorHandling
            element={children(input)}
            {...meta}
            serverError={serverError}
          />
        )}
      />
    </Form.Group>
  );
}
