import { Auth, Hub } from "aws-amplify";
import * as React from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { getCurrentUser, PortalUser } from "../_helpers";
import logo from "../_images/AP_wh_txt_logo.png";
import { useMatch } from "react-router-dom";

//  I'm intentionally making the authentication hidden from the rest of the app for now.  In other words, no one
//  outside of this component can call sign in or sign out.  Perhaps we change that eventually.
//  To get the current user, there is a public function in the utilities file.
interface UseAuthHookResponse {
  currentUser: PortalUser | null;
  signIn: () => void;
  signOut: () => void;
}

const useAuth = (): UseAuthHookResponse => {
  const [currentUser, setCurrentUser] = React.useState<PortalUser | null>(null);

  const logoutMatch = useMatch("/logout");

  React.useEffect(() => {
    const updateUser = async ({ payload }: { payload: { event: string } }) => {
      if (payload.event !== "oAuthSignOut" && !logoutMatch) {
        const user = await getCurrentUser();
        setCurrentUser(user);
        if (user === null) {
          await signIn();
        }
      }
    };
    Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser({ payload: { event: "" } }); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", updateUser);
  }, [logoutMatch]);

  const signIn = () =>
    Auth.federatedSignIn({
      customProvider: process.env.REACT_APP_OAUTH_PROVIDER as string,
    });

  const signOut = () => Auth.signOut({ global: true });

  return { currentUser, signIn, signOut };
};

export const Header = () => {
  const { currentUser, signOut, signIn } = useAuth();

  return (
    <Navbar bg="dark" variant="dark" fixed="top" className="p-2">
      <Navbar.Brand>
        <img
          src={logo}
          height={50}
          width={100}
          className="d-inline-block align-middle"
          alt="AccuPrecision"
        />{" "}
        Customer Portal
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        {currentUser ? (
          <Navbar.Text>
            Hi, {currentUser?.attributes?.given_name}{" "}
            {currentUser?.attributes?.family_name}
            <br />
            <Button variant="link" onClick={signOut}>
              Sign Out
            </Button>
          </Navbar.Text>
        ) : (
          <Navbar.Text>
            <Button variant="link" onClick={signIn}>
              Sign In
            </Button>
          </Navbar.Text>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};
