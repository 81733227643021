import axios, { AxiosError } from "axios";
import { getCurrentUser } from "../_helpers";
import { ProblemJsonResponse } from "../_types";

async function getConfig() {
  const user = await getCurrentUser();
  const idToken = user?.getSignInUserSession()?.getIdToken().getJwtToken();
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };

  return config;
}

export async function get<T, R>(url: string, params?: R) {
  const config = await getConfig();
  return axios.get<T>(url, { ...config, params });
}

export async function post<T>({ url, body }: { url: string; body: T }) {
  const config = await getConfig();

  try {
    await axios.post<T>(url, body, config);
    return null;
  } catch (e: unknown) {
    const axiosError = e as AxiosError<ProblemJsonResponse>;
    if (
      axiosError &&
      (axiosError?.response?.status === 400 ||
        axiosError?.response?.status === 422)
    ) {
      return axiosError?.response?.data;
    }
    throw e;
  }
}

export async function put<T>({ url, body }: { url: string; body: T }) {
  const config = await getConfig();

  try {
    await axios.put<T>(url, body, config);
    return null;
  } catch (e: unknown) {
    const axiosError = e as AxiosError<ProblemJsonResponse>;
    if (
      axiosError &&
      (axiosError?.response?.status === 400 ||
        axiosError?.response?.status === 422)
    ) {
      return axiosError?.response?.data;
    }
    throw e;
  }
}

export async function deleteItem(url: string) {
  const config = await getConfig();
  return axios.delete(url, config);
}
