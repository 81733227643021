import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  ActionFunction,
  LoaderFunction,
  useFetcher,
  useParams,
} from "react-router-dom";
import { UserRole, getUserRole } from "../../_helpers";
import {
  getSubscriptionStatus,
  subscribeToCustomer,
  unsubscribeFromCustomer,
} from "../../_gateways/customerGateways";
import { SubscriptionStatus } from "../../_types";
import { SubscriptionForm } from "./SubscribtionForm";
import { BellFill } from "react-bootstrap-icons";

export const subscriptionStatusLoader: LoaderFunction = async ({ params }) => {
  const userRole = await getUserRole();

  const { customerId } = params;
  if (!customerId)
    throw new Error("Cannot fetch subscription status due to bad route.");

  switch (userRole as UserRole) {
    case UserRole.Admin:
    case UserRole.SalesPerson:
      return await getSubscriptionStatus({ customerId });
    case UserRole.Contact:
    default:
      throw new Error("Unauthorized.");
  }
};

export const subscribeToCustomerAction: ActionFunction = async ({ params }) => {
  const userRole = await getUserRole();

  const { customerId } = params;
  if (!customerId)
    throw new Error(
      "Cannot subscribe to customer due to bad route. Please try again."
    );

  switch (userRole as UserRole) {
    case UserRole.Admin:
    case UserRole.SalesPerson:
      return await subscribeToCustomer({
        customerId,
      });
    case UserRole.Contact:
    default:
      throw new Error("Unauthorized.");
  }
};

export const unsubscribeToCustomerAction: ActionFunction = async ({
  params,
}) => {
  const userRole = await getUserRole();

  const { customerId } = params;
  if (!customerId)
    throw new Error(
      "Cannot unsubscribe from customer due to bad route. Please try again."
    );

  switch (userRole as UserRole) {
    case UserRole.Admin:
    case UserRole.SalesPerson:
      return await unsubscribeFromCustomer({
        customerId,
      });
    case UserRole.Contact:
    default:
      throw new Error("Unauthorized.");
  }
};

export const SubscriptionModal = () => {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const fetcher = useFetcher<SubscriptionStatus>();
  const { customerId } = useParams();
  const formId = "subscriptionManagementForm";

  React.useEffect(() => {
    if (show && fetcher.state === "idle" && !fetcher.data) {
      fetcher.load(`/customer/${customerId}/subscriptionStatus`);
    }
  }, [fetcher, customerId, show]);

  const handleOnSubmit = async (values: Record<string, string>) => {
    const { subscribe, unsubscribe } = values;

    if (subscribe) {
      fetcher.submit(
        {},
        {
          method: "post",
          action: `/customer/${customerId}/subscribe`,
        }
      );
    }

    if (unsubscribe) {
      fetcher.submit(
        {},
        {
          method: "delete",
          action: `/customer/${customerId}/unsubscribe`,
        }
      );
    }

    handleClose();
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        disabled={fetcher.state !== "idle"}
      >
        <BellFill />
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Manage Subscription</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {(fetcher.data === undefined || fetcher.state !== "idle") && (
            <>
              {"Loading subscription status... "}
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          )}

          {fetcher.data?.isSubscribed !== undefined &&
            fetcher.state === "idle" && (
              <>
                <div className="mb-3">
                  You are {fetcher.data.isSubscribed ? "" : "not "} currently
                  subscribed.
                </div>
                <SubscriptionForm
                  formId={formId}
                  isSubscribed={fetcher.data.isSubscribed}
                  handleOnSubmit={handleOnSubmit}
                />
              </>
            )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={fetcher.state !== "idle"}
          >
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={fetcher.state !== "idle"}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
