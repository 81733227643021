import { Form } from "react-bootstrap";
import { Field as FinalField } from "react-final-form";
import {
  createLocalizedDateFromDateOnlyString,
  formatDateOnlyString,
} from "../../_helpers";

export interface DatePickerProps {
  name: string;
  label: string;
  formGroupClassName?: string;
  labelClassName?: string;
  serverError?: string[];
}

export function DatePicker({
  name,
  label,
  formGroupClassName,
  labelClassName,
  serverError,
}: DatePickerProps) {
  return (
    <FinalField
      name={name}
      render={({ input, meta }) => (
        <Form.Group className={formGroupClassName} controlId={name}>
          <Form.Label className={labelClassName}>{label}</Form.Label>
          <Form.Control
            {...input}
            onChange={(e) => {
              // value is empty string when user clicks "clear" inside datepicker to clear date
              return e.target.value === ""
                ? input.onChange(e.target.value)
                : input.onChange(
                    createLocalizedDateFromDateOnlyString(e.target.value)
                  );
            }}
            type="date"
            value={input.value ? formatDateOnlyString(input.value) : ""}
            isInvalid={meta.error || serverError}
          />
          {((meta.touched && meta.error) || serverError) && (
            <Form.Control.Feedback type="invalid">
              {meta.error ?? serverError}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      )}
    />
  );
}
