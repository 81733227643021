import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import noImage from "../../_images/no_image.png";

/**
 * Calculates the correct aspect ratio to display the image within it's container
 * @param srcHeight
 * @param srcWidth
 * @param maxHeight
 * @param maxWidth
 * @returns
 */
const calculateFit = (
  srcHeight: number,
  srcWidth: number,
  maxHeight: number,
  maxWidth: number
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

/**
 * Used to ensure the zoomed in image is displayed within the bounds of the page
 * @param elementRef The element the zoomed image element will attach itself to
 * @param itemHeight How tall you want the image to be, default `500`
 * @param normalPosition The position of the image in regards to the thumbnail, default `0`
 * @returns
 */
const calculateTop = (
  elementRef: React.RefObject<HTMLDivElement>,
  itemHeight: number = 500,
  normalPosition: number = 0
) => {
  if (!elementRef.current) {
    return normalPosition;
  }
  if (!window) {
    return normalPosition;
  }
  const { innerHeight } = window;
  const { top } = elementRef.current.getBoundingClientRect();
  const distanceToBottom = innerHeight - top;

  return distanceToBottom < itemHeight
    ? distanceToBottom - itemHeight
    : normalPosition;
};

export declare interface ImageMagnifierProps {
  alt?: string;
  fallback?: string;
  highRezImageUrl: string;
  lowRezImageUrl: string;
  smallImageHeight?: number;
  zoomedImageMaxHeight?: number;
  zoomedImageMaxWidth?: number;
}

/**
 * A component that allows a user to hover over an image and see a zoomed in version of that image
 * @param ImageMagnifierProps.alt the alt text you'd like associated with the image, defaults to
 * `"image thumbnail"` and `"image thumbnail-zoomed"`
 * @param ImageMagnifierProps.fallback the fallback image you'd like to use if the image fails to load.
 * Defaults to `noImage`
 * @param ImageMagnifierProps.highRezImageUrl the url for the high-rez image
 * @param ImageMagnifierProps.lowRezImageUrl the url for the low-rez image
 * @param ImageMagnifierProps.smallImageHeight the height of the thumbnail image, defaults to `23`
 * @param ImageMagnifierProps.zoomedImageMaxHeight the max height allowed for the zoomed image, defaults to `500`
 * @param ImageMagnifierProps.zoomedImageMaxWidth the max width allowed for the zoomed image, defaults to `500`
 */
export const ImageMagnifier = ({
  alt = "image thumbnail",
  fallback = noImage,
  highRezImageUrl,
  lowRezImageUrl,
  smallImageHeight = 23,
  zoomedImageMaxHeight = 500,
  zoomedImageMaxWidth = 500,
}: ImageMagnifierProps) => {
  const elementRef = React.useRef<HTMLDivElement>(null);

  const [zoomSize, setZoomSize] = React.useState({
    height: zoomedImageMaxHeight,
    width: zoomedImageMaxWidth,
  });

  /**
   * Will display the noImage file if an error occurs when loading the image
   */
  const onImageLoadError = (
    e: React.SyntheticEvent<HTMLImageElement, Event> & {
      target: HTMLImageElement;
    }
  ) => {
    e.target.src = fallback;
  };

  /**
   * Calculates the size and location of the zommed image once the image has loaded
   */
  const onLargeImageLoad = (
    e: React.SyntheticEvent<HTMLImageElement, Event> & {
      target: HTMLImageElement;
    }
  ) => {
    setZoomSize(
      calculateFit(
        e.target.naturalHeight,
        e.target.naturalWidth,
        zoomedImageMaxHeight,
        zoomedImageMaxWidth
      )
    );
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body
        style={{
          background: "white",
          position: "absolute",
          top: calculateTop(elementRef),
          zIndex: 9999,
        }}
      >
        <img
          alt={`${alt}-zoomed`}
          onError={onImageLoadError}
          onLoad={onLargeImageLoad}
          src={highRezImageUrl}
          style={zoomSize}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <div ref={elementRef}>
      <OverlayTrigger
        placement="right"
        trigger={["focus", "hover"]}
        overlay={popover}
      >
        <img
          alt={alt}
          height={smallImageHeight}
          width={smallImageHeight * 1.27} // This fixes the page rendering jumping for... reasons?
          onError={onImageLoadError}
          src={lowRezImageUrl}
        />
      </OverlayTrigger>
    </div>
  );
};
