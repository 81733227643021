import * as React from "react";
import {
  Form,
  LoaderFunction,
  Outlet,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row } from "react-bootstrap";
import { Form as FinalForm } from "react-final-form";
import { Customers } from "../../_types";
import { UserRole, getUserRole } from "../../_helpers";
import { FormSelect } from "../../_components/_finalFormSpecific";
import {
  getCustomersForAdmin,
  getCustomersForSalesPerson,
  getCustomersForContact,
} from "../../_gateways/customerGateways";
import { CustomerOrderFilterModal } from "./CustomerOrderFilterModal";
import { SubscriptionModal } from "../../_components/Subscriptions";

export const customersLoader: LoaderFunction = async ({ params }) => {
  const userRole = await getUserRole();

  // Determine which endpoint to call:
  switch (userRole as UserRole) {
    case UserRole.Admin:
      return await getCustomersForAdmin();
    case UserRole.SalesPerson:
      return await getCustomersForSalesPerson();
    case UserRole.Contact:
      return await getCustomersForContact();
    default:
      throw new Error("Unauthorized.");
  }
};

export const CustomerSelectionLayout = () => {
  const [userRole, setUserRole] = React.useState<UserRole>();
  const data = useLoaderData() as Customers;
  const { customerId } = useParams();
  const navigate = useNavigate();

  React.useMemo(() => {
    (async () => {
      const role = await getUserRole();
      setUserRole(role);
    })();
  }, []);

  // This will be used for a dropdown if the contact has multiple vendors
  // Again, this is a redirect index page under /customer
  const onSelectionChange = (e: React.ChangeEvent<HTMLElement>) => {
    if (e.target instanceof HTMLSelectElement) {
      return navigate(`${e.target.value}`);
    }

    return null;
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {data.length > 1 ? (
            <FinalForm
              initialValues={{ customerId }}
              onSubmit={() => {}}
              render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col xs={3}>
                      <FormSelect
                        name="customerId"
                        label="Select Customer"
                        options={data}
                        serverError=""
                        onChange={onSelectionChange}
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            />
          ) : (
            <h4>{data[0].name}</h4>
          )}
        </Col>
        {(userRole === UserRole.Admin || userRole === UserRole.SalesPerson) && (
          <Col xs="auto">
            <SubscriptionModal />
          </Col>
        )}
        <Col xs="auto">
          <CustomerOrderFilterModal />
        </Col>
        <Outlet />
      </Row>
    </Container>
  );
};
