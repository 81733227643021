import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

export const notNull = <T>(value: T | null): value is T => value !== null;

// The CognitoUser, as defined out of the box, doesn't include attributes, even though they are there
export interface PortalUser extends CognitoUser {
  attributes: {
    email: string;
    email_verified?: boolean;
    family_name?: string;
    given_name?: string;
    preferred_username?: string;
    sub?: string;
    "custom:groups"?: string[];
  };
}

export const getCurrentUser = async (): Promise<PortalUser | null> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch {
    // currentAuthenticatedUser throws an Error if not signed in
    return null;
  }
};

export enum UserRole {
  None,
  Contact,
  SalesPerson,
  Admin,
}

export const getUserRole = async (): Promise<UserRole> => {
  const user = await getCurrentUser();
  const groups = user?.attributes["custom:groups"] || [];

  if (groups.indexOf("customer.portal.admin") > -1) {
    return UserRole.Admin;
  } else if (groups.indexOf("customer.portal.sales") > -1) {
    return UserRole.SalesPerson;
  } else if (groups.indexOf("customer.contact") > -1) {
    return UserRole.Contact;
  }
  return UserRole.None;
};

export const convertAcuuPrecImageUrlResolution = (url: string): string => {
  if (url.indexOf("HIGHREZ") !== -1)
    return url.replace(/(highrez.png)/gi, "LOWREZ.png");

  if (url.indexOf("highrez") !== -1)
    return url.replace(/(highrez.png)/gi, "lowrez.png");

  if (url.indexOf("LOWREZ") !== -1)
    return url.replace(/(lowrez.png)/gi, "HIGHREZ.png");

  if (url.indexOf("lowrez") !== -1)
    return url.replace(/(lowrez.png)/gi, "highrez.png");

  return url;
};
