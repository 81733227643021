const dateFormatter = new Intl.DateTimeFormat("en-us");

/**
 * Formats a Date object in MM/DD/YYYY format.
 */
export function formatDate(incomingDate: Date) {
  return dateFormatter.format(incomingDate);
}

function AddMinutesToDate(date: Date, minutes: number) {
  return new Date(new Date(date).getTime() + minutes * 60000);
}

function SubtractMinutesFromDate(date: Date, minutes: number) {
  return new Date(new Date(date).getTime() - minutes * 60000);
}

/**
 * Given a DateOnly string in YYYY-MM-DD format, returns a date object with a user-localized midnight time (00:00:00)
 */
export function createLocalizedDateFromDateOnlyString(
  incomingDateOnlyString: string
) {
  const rawDate = new Date(Date.parse(incomingDateOnlyString));
  const offsetInMinutes = rawDate.getTimezoneOffset();
  const newDate = AddMinutesToDate(rawDate, offsetInMinutes);
  return newDate;
}

/**
 * Given a DateTime timestamp string from the server (UTC time), returns a date object with a user-localized time
 */
export function convertUTCTimestampStringToLocalDateTime(
  incomingUTCTimestampString: string
) {
  const rawDate = new Date(Date.parse(incomingUTCTimestampString));
  const offsetInMinutes = rawDate.getTimezoneOffset();
  const newDate = SubtractMinutesFromDate(rawDate, offsetInMinutes);
  const formattedDate = newDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return formattedDate;
}

/**
 * Formats an ISO date string in YYYY-MM-DD format.
 */
export function formatDateOnlyStringFromString(incomingDate: string) {
  return incomingDate.split("T")[0];
}

/**
 * Formats a Date object in YYYY-MM-DD format.
 */
export function formatDateOnlyString(incomingDate: Date) {
  return formatDateOnlyStringFromString(incomingDate.toISOString());
}

/**
 * Transform function to use with zod parsing when you need to convert a returned
 * DateOnly string (YYYY-MM-DD) into a localized JS Date object, or null if the returned value is null
 */
export function transformDateOnlyString(incomingDateOnlyString: string | null) {
  return incomingDateOnlyString
    ? createLocalizedDateFromDateOnlyString(incomingDateOnlyString)
    : null;
}

/**
 * Transform function to use with zod parsing when you need to convert a JS Date that has
 * been stringified into a DateOnly string (YYYY-MM-DD), or null if the returned value is null
 */
export function transformDateStringToDateOnlyString(
  incomingDateString: string | null | undefined
) {
  return incomingDateString
    ? formatDateOnlyStringFromString(
        new Date(Date.parse(incomingDateString)).toISOString()
      )
    : null;
}
