import * as React from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { Customers } from "../../_types";

export const CustomerSelectionPage = () => {
  const data = useRouteLoaderData("customer") as Customers;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (data.length > 0) {
      navigate(`${data[0].id}`, { replace: true });
    }
  }, [data, navigate]);

  return <p>Redirecting to first customer...</p>;
};
