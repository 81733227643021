import * as React from "react";
import BTable from "react-bootstrap/Table";
import { flexRender, Table } from "@tanstack/react-table";
import { ArrowUp, ArrowDown } from "react-bootstrap-icons";
import { TextFilter } from "./TableFilterComponents/TextFilter";

interface BootstrapTableProps<T> {
  table: Table<T>;
  showHeaders?: boolean;
  showFooters?: boolean;
}

export function BootstrapTable<T>({
  table,
  showHeaders = true,
  showFooters = false,
}: BootstrapTableProps<T>) {
  return (
    <BTable striped bordered hover responsive size="sm">
      {showHeaders && (
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <>
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="align-middle"
                  >
                    {header.isPlaceholder ? null : (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <ArrowUp />,
                          desc: <ArrowDown />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
              {headerGroup.headers.some((h) => h.column.getCanFilter()) && (
                <tr key={`${headerGroup.id}_filter`}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={`${header.id}_filter`}
                      colSpan={header.colSpan}
                      className="align-middle"
                    >
                      {header.column.getCanFilter()
                        ? (
                            header.column.columnDef.meta?.filterRenderer ??
                            TextFilter
                          )(header.column)
                        : null}
                    </th>
                  ))}
                </tr>
              )}
            </>
          ))}
        </thead>
      )}
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="align-middle">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      {showFooters && (
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className="align-middle"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      )}
    </BTable>
  );
}
