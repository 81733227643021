import React from "react";
import { Form } from "react-bootstrap";

export function FormFieldWithErrorHandling({
  element,
  touched = false,
  error = undefined,
  serverError = undefined,
}: {
  element: React.ReactElement;
  touched?: boolean;
  error?: string;
  serverError?: string | string[] | undefined;
}) {
  return (
    <>
      {React.cloneElement(element, {
        isInvalid: (touched && error) || serverError,
      })}
      {((touched && error) || serverError) && (
        <Form.Control.Feedback type="invalid">
          {error ?? serverError}
        </Form.Control.Feedback>
      )}
    </>
  );
}
