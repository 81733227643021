import React from "react";
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import { OverlayProps } from "react-bootstrap/Overlay";
import { OverlayTriggerType } from "react-bootstrap/OverlayTrigger";
import { useParams } from "react-router-dom";
import { JobDueDatePartStatus, JobDueDatePartStatuses } from "../_types";
import { getJobDueDatePartStatuses } from "../_gateways/jobDueDateGateways";

export declare interface DueDateQuantityProps {
  orderId: number;
  jobDueDateId: number;
  jobDueDateQuantity: number;
  popoverDirection?: OverlayProps["placement"];
  trigger?: OverlayTriggerType | OverlayTriggerType[];
}

export const DueDateQuantityCell = ({
  orderId,
  jobDueDateId,
  jobDueDateQuantity,
  popoverDirection = "right",
  trigger = ["focus", "hover"],
}: DueDateQuantityProps) => {
  const { customerId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [jobPartStatusData, setJobPartStatusData] =
    React.useState<JobDueDatePartStatuses>([]);

  const fetchDataOnPopoverDisplay = async (nextShow: boolean) => {
    if (!customerId || !nextShow) {
      // "nextShow" is true when it should display, false when hidden
      setJobPartStatusData([]);
      return;
    }

    setLoading(true);

    const data = await getJobDueDatePartStatuses({
      customerId,
      orderId,
      jobDueDateId,
    });

    setJobPartStatusData(data);
    setLoading(false);
  };

  return (
    <OverlayTrigger
      placement={popoverDirection}
      trigger={trigger}
      onToggle={fetchDataOnPopoverDisplay}
      overlay={
        <Popover id="popover-basic">
          <Popover.Body
            style={{
              background: "white",
            }}
          >
            <Table borderless size="sm" striped>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={2}>Loading...</td>
                  </tr>
                )}

                {!loading && jobPartStatusData.length === 0 && (
                  <tr>
                    <td colSpan={2}>No data.</td>
                  </tr>
                )}

                {!loading &&
                  jobPartStatusData.length > 0 &&
                  jobPartStatusData.map((jddps: JobDueDatePartStatus) => (
                    <tr key={jddps.jobDueDatePartStatusId}>
                      <td>{jddps.jobPartStatus}</td>
                      <td>{jddps.jobPartStatusQty}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Popover.Body>
        </Popover>
      }
    >
      <div>{jobDueDateQuantity}</div>
    </OverlayTrigger>
  );
};
