import { z } from "zod";
import {
  transformDateOnlyString,
  transformDateStringToDateOnlyString,
} from "../_helpers/dateTime";

export const jobDueDateDetailsSchema = z.object({
  customerName: z.string(),
  customerPONumber: z.string().nullable(),
  jobNumber: z.string(),
  partNumber: z.string(),
  revision: z.string().nullable(),
  jobDueDateId: z.number(),
  externalPortalStatusDisplay: z.string().nullable(),
  originalDueDate: z.string().nullable().transform(transformDateOnlyString),
  askDate: z.string().nullable().transform(transformDateOnlyString),
  modifiedDueDate: z.string().nullable().transform(transformDateOnlyString),
  dueDateQuantity: z.number(),
  atRisk: z.boolean(),
  contactName: z.string().nullable(),
  canViewJobNumbers: z.boolean(),
  canEditAskDate: z.boolean(),
});
export type JobDueDateDetails = z.infer<typeof jobDueDateDetailsSchema>;

export const updateAskDateSchema = z.object({
  askDate: z.string().optional().transform(transformDateStringToDateOnlyString),
});
export type UpdateAskDate = z.infer<typeof updateAskDateSchema>;

export interface UpdateAskDateErrors {
  errors: {
    AskDate: string[];
  };
}

export const jobDueDatePartStatusSchema = z.object({
  jobDueDatePartStatusId: z.number(),
  jobPartStatus: z.string(),
  jobPartStatusQty: z.number(),
});
export type JobDueDatePartStatus = z.infer<typeof jobDueDatePartStatusSchema>;

export const jobDueDatePartStatusesSchema = jobDueDatePartStatusSchema.array();
export type JobDueDatePartStatuses = z.infer<
  typeof jobDueDatePartStatusesSchema
>;

export const jobDueDatePackingListSchema = z.object({
  packingListId: z.number(),
  shipDate: z.string().nullable().transform(transformDateOnlyString),
  trackingNumber: z.string().array(),
  packedQuantity: z.number(),
});
export type JobDueDatePackingList = z.infer<typeof jobDueDatePackingListSchema>;

export const jobDueDatePackingListsSchema = jobDueDatePackingListSchema.array();
export type JobDueDatePackingLists = z.infer<
  typeof jobDueDatePackingListsSchema
>;
