import { Col } from "react-bootstrap";

export interface HeaderDetailItemProps {
  label: string;
  value: string | number | null;
}

export function HeaderDetailItem({ label, value }: HeaderDetailItemProps) {
  return (
    <Col xs={12} sm={4} lg={3} xl={2} xxl className="p-1">
      <div>
        <strong>{label}</strong>
      </div>
      {value}
    </Col>
  );
}
