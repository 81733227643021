import {
  Column,
  createColumnHelper,
  getCoreRowModel,
  RowData,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import {
  Link,
  LoaderFunction,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import {
  FilterOptions,
  Order,
  OrdersPageData,
  PagingSortingFilteringParams,
} from "../../../_types";
import {
  formatDate,
  getFilteringParams,
  getOrderFilterParams,
  getPagingParams,
  getSortingParams,
  nameofFactory,
  getUserRole,
  UserRole,
} from "../../../_helpers";
import { BootstrapTable, CustomTablePagination } from "../../../_components";
import React from "react";
import { PartImageMagnifier } from "../../../_components/PartImageMagnifier";
import {
  useQueryStringFiltering,
  useQueryStringPagination,
  useQueryStringSingleSort,
} from "../../../_hooks";
import { DropdownFilter } from "../../../_components/TableFilterComponents/DropdownFilter";
import { Button } from "react-bootstrap";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import {
  getCustomerOrdersForAdmin,
  getCustomerOrdersForContact,
  getCustomerOrdersForSalesPerson,
  getExternalPortalStatuses,
} from "../../../_gateways/orderGateways";
import { DueDateQuantityCell } from "../../../_components";
import { QBIC_APP_URL } from "../../../constants";

export function getJobUrl(jobId: number) {
  return `${QBIC_APP_URL}/jobs/${jobId}`;
}

declare module "@tanstack/table-core" {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterRenderer: (column: Column<TData, TValue>) => JSX.Element;
    filterOptions?: FilterOptions;
  }
}

export const ordersLoader: LoaderFunction = async ({ request, params }) => {
  const userRole = await getUserRole();

  const { customerId } = params;
  if (!customerId)
    throw new Error("Cannot fetch data due to bad route. Please try again.");

  const pagingParams = getPagingParams(request);
  const sortingParams = getSortingParams(request);
  const filteringParams = getFilteringParams(request);
  const orderFilterParams = getOrderFilterParams(request);
  const queryParams: PagingSortingFilteringParams = {
    ...pagingParams,
    ...sortingParams,
    ...filteringParams,
    ...orderFilterParams,
  };

  const externalPortalStatusFilters = await getExternalPortalStatuses();

  // Determine which endpoint to call:
  switch (userRole as UserRole) {
    case UserRole.Admin:
      return {
        data: await getCustomerOrdersForAdmin({
          customerId,
          params: queryParams,
        }),
        externalPortalStatusFilters,
      };
    case UserRole.SalesPerson:
      return {
        data: await getCustomerOrdersForSalesPerson({
          customerId,
          params: queryParams,
        }),
        externalPortalStatusFilters,
      };
    case UserRole.Contact:
      return {
        data: await getCustomerOrdersForContact({
          customerId,
          params: queryParams,
        }),
        externalPortalStatusFilters,
      };
    default:
      throw new Error("Unauthorized.");
  }
};

export function CustomerDataPage() {
  const { onFilterChange, getFilterState } = useQueryStringFiltering();
  const { onPaginationChange, getPaginationState } = useQueryStringPagination();
  const { onSortingChange, getSortingState } = useQueryStringSingleSort();
  const { data, externalPortalStatusFilters } =
    useLoaderData() as OrdersPageData;
  const nameof = nameofFactory<Order>();
  const { search } = useLocation();

  const columnHelper = createColumnHelper<Order>();
  const columns = React.useMemo(() => {
    var generatedColumns = [
      columnHelper.accessor((row: Order) => row, {
        header: "",
        id: nameof("lowRezImage"),
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => (
          <PartImageMagnifier
            alt={`${info.getValue()}-image`}
            highRezImageUrl={info.getValue().highRezImage}
            lowRezImageUrl={info.getValue().lowRezImage}
          />
        ),
      }),
      columnHelper.accessor((row: Order) => row, {
        header: "Job Number",
        id: nameof("jobNumber"),
        cell: (info) => (
          <a
            href={`${getJobUrl(info.getValue().jobId)}`}
            target="_blank"
            rel="noreferrer"
          >
            {info.getValue().jobNumber}
          </a>
        ),
      }),
      columnHelper.accessor((row: Order) => row.customerPONumber, {
        header: "Customer PO#",
        id: nameof("customerPONumber"),
      }),
      columnHelper.accessor((row: Order) => row.contactName, {
        header: "Buyer",
        id: nameof("contactName"),
      }),
      columnHelper.accessor((row: Order) => row, {
        header: "Part #",
        id: nameof("partNumber"),
        cell: (info) =>
          `${info.getValue().partNumber} - Rev: ${info.getValue().revision}`,
      }),
      columnHelper.accessor((row: Order) => row, {
        header: "Qty",
        id: nameof("dueDateQuantity"),
        enableColumnFilter: false,
        cell: (info) => (
          <DueDateQuantityCell
            orderId={info.getValue().orderId}
            jobDueDateId={info.getValue().jobDueDateId}
            jobDueDateQuantity={info.getValue().dueDateQuantity}
          />
        ),
      }),
      columnHelper.accessor((row: Order) => row.externalPortalStatusDisplay, {
        header: "Status",
        id: nameof("externalPortalStatusDisplay"),
        // TODO: Right now this causes a null exception but unsure why
        // Other filters like it for the PO page work fine
        enableColumnFilter: false,
        meta: {
          filterRenderer: DropdownFilter,
          filterOptions: externalPortalStatusFilters,
        },
      }),
      columnHelper.accessor((row: Order) => row.originalDueDate, {
        header: "Original Due Date",
        id: nameof("originalDueDate"),
        enableColumnFilter: false, // need to figure out good way to filter based on dateOnly and strings
        cell: (info) => {
          const ogDate = info.getValue();
          return ogDate !== null ? formatDate(ogDate) : "";
        },
      }),
      columnHelper.accessor((row: Order) => row.askDate, {
        header: "Ask Date",
        id: nameof("askDate"),
        enableColumnFilter: false, // need to figure out good way to filter based on dateOnly and strings
        cell: (info) => {
          const askDate = info.getValue();
          return askDate !== null ? formatDate(askDate) : "";
        },
      }),
      columnHelper.accessor((row: Order) => row.modifiedDueDate, {
        header: "Modified Due Date",
        id: nameof("modifiedDueDate"),
        enableColumnFilter: false, // need to figure out good way to filter based on dateOnly and strings
        cell: (info) => {
          const modDate = info.getValue();
          return modDate !== null ? formatDate(modDate) : "";
        },
      }),
      columnHelper.accessor((row: Order) => row.atRisk, {
        header: "At Risk?",
        id: nameof("atRisk"),
        enableColumnFilter: false,
        cell: (info) => (info.getValue() ? "Y" : "N"),
      }),
      columnHelper.accessor((row: Order) => row, {
        header: "Details",
        id: "details",
        enableColumnFilter: false,
        cell: (info) => (
          <Link
            to={`order/${info.getValue().orderId}/jobDueDate/${
              info.getValue().jobDueDateId
            }/details${search}`}
          >
            <Button variant="primary">
              <ArrowRightCircleFill />
            </Button>
          </Link>
        ),
      }),
    ];

    // Conditionally remove JobNumber based on user being Admin or SalesPerson
    const filteredColumns = data.canViewJobNumbers
      ? generatedColumns
      : generatedColumns.filter((c) => c.id !== nameof("jobNumber"));

    return filteredColumns;
  }, [
    columnHelper,
    data.canViewJobNumbers,
    externalPortalStatusFilters,
    nameof,
  ]);

  const paginationState = getPaginationState();
  const options: TableOptions<Order> = {
    columns,
    data: data.results,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    pageCount: Math.ceil(data.total / paginationState.pageSize),
    state: {
      columnFilters: getFilterState(),
      pagination: paginationState,
      sorting: getSortingState(),
    },
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: onFilterChange,
    onPaginationChange,
    onSortingChange,
  };
  const table = useReactTable(options);

  return (
    <>
      <BootstrapTable table={table} />
      <CustomTablePagination table={table} />
    </>
  );
}
