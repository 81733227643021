import React from "react";
import ReactDOM from "react-dom";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import { reportWebVitals } from "./reportWebVitals";
import { LogoutPage } from "./_pages/LogoutPage";
import {
  CustomerDataPage,
  ordersLoader,
} from "./_pages/customer/$customerId/CustomerDataPage";
import { AppPage } from "./_pages/AppPage";
import { CustomerSelectionPage } from "./_pages/customer/CustomerSelectionPage";
import { AppLayout } from "./_pages/AppLayout";
import {
  CustomerSelectionLayout,
  customersLoader,
} from "./_pages/customer/CustomerSelectionLayout";
import { JobDueDateLayout } from "./_pages/customer/$customerId/jobDueDate/JobDueDateLayout";
import {
  jobDueDateDetailsLoader,
  JobDueDateDetailsPage,
  updateAskDate,
} from "./_pages/customer/$customerId/jobDueDate/$jobDueDateId/details/JobDueDateDetailsPage";
import {
  jobDueDateShippingLoader,
  JobDueDateShippingPage,
} from "./_pages/customer/$customerId/jobDueDate/$jobDueDateId/shipping/JobDueDateShippingPage";
import {
  jobDueDateMessagesLoader,
  JobDueDateMessagingPage,
  sendMessage,
} from "./_pages/customer/$customerId/jobDueDate/$jobDueDateId/messaging/JobDueDateMessagingPage";
import { ErrorComponent } from "./_components";
import {
  subscriptionStatusLoader,
  subscribeToCustomerAction,
  unsubscribeToCustomerAction,
} from "./_components/Subscriptions";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <AppPage />,
      },
      {
        errorElement: <ErrorComponent />,
        children: [
          {
            path: "customer",
            id: "customer",
            element: <CustomerSelectionLayout />,
            loader: customersLoader,
            children: [
              { index: true, element: <CustomerSelectionPage /> },
              {
                path: ":customerId",
                element: <CustomerDataPage />,
                loader: ordersLoader,
                children: [
                  {
                    path: "subscriptionStatus",
                    loader: subscriptionStatusLoader,
                  },
                  {
                    path: "subscribe",
                    action: subscribeToCustomerAction,
                  },
                  {
                    path: "unsubscribe",
                    action: unsubscribeToCustomerAction,
                  },
                ],
              },
            ],
          },
          {
            path: "customer/:customerId/order/:orderId/jobDueDate/:jobDueDateId",
            element: <JobDueDateLayout />,
            children: [
              {
                index: true,
                element: <JobDueDateDetailsPage />,
                loader: jobDueDateDetailsLoader,
              },
              {
                path: "details",
                element: <JobDueDateDetailsPage />,
                loader: jobDueDateDetailsLoader,
                action: updateAskDate,
              },
              {
                path: "shipping",
                element: <JobDueDateShippingPage />,
                loader: jobDueDateShippingLoader,
              },
              {
                path: "messaging",
                element: <JobDueDateMessagingPage />,
                loader: jobDueDateMessagesLoader,
                action: sendMessage,
              },
            ],
          },
          {
            path: "*",
            element: <Navigate to="/" replace />,
          },
        ],
      },
      {
        path: "logout",
        element: <LogoutPage />,
      },
    ],
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
