import { createSearchParams, useSearchParams } from "react-router-dom";
import { ColumnFiltersState, Updater } from "@tanstack/react-table";

// Passing in a specific schema allows the hook to only worry about the
// specific filters the parent component cares about
export function useQueryStringFiltering() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getFilterState = (): ColumnFiltersState => {
    // Populate two lists with the current filterFields and filterValues searchParams
    // Unable to zod parse duplicate keys with distinct values
    const filterFields = searchParams.getAll("filterFields");
    const filterValues = searchParams.getAll("filterValues");

    const filterState: ColumnFiltersState = [];

    // Build the ColumnFiltersState array used by the table
    filterFields.map((value, i) =>
      filterState.push({ id: value, value: filterValues[i] })
    );

    return filterState;
  };

  const setFilterState = (newColumnFilterState: ColumnFiltersState): void => {
    if (newColumnFilterState.length === 0) {
      // No filters, delete any filter data in searchParams and update searchParams
      // Delete will delete ALL instances of the key. No option to be picky.
      searchParams.delete("filterFields");
      searchParams.delete("filterValues");
      setSearchParams({ ...Object.fromEntries(searchParams) });
    } else {
      // Populate two lists with the current filterFields and filterValues searchParams
      // Unable to zod parse duplicate keys with distinct values
      const filterFields = newColumnFilterState.map(
        (colFilter) => colFilter.id
      );
      const filterValues = newColumnFilterState.map(
        (colFilter) => colFilter.value as string
      );

      // Create the newSearchParams object with the updated filter data
      const newFilterParams: URLSearchParams = createSearchParams({
        ...Object.fromEntries(searchParams),
        filterFields,
        filterValues,
      });

      setSearchParams(newFilterParams);
    }
  };

  const onFilterChange = (updater: Updater<ColumnFiltersState>): void => {
    const newFilteredState =
      typeof updater === "function" ? updater(getFilterState()) : updater;
    setFilterState(newFilteredState);
  };

  return {
    onFilterChange,
    getFilterState,
    setFilterState,
  };
}
