import { z } from "zod";
import { convertUTCTimestampStringToLocalDateTime } from "../_helpers/dateTime";

export const jobDueDateMessageDropdownOptionSchema = z.object({
  customerPortalMessageId: z.number(),
  message: z.string(),
});
export type JobDueDateMessageDropdownOption = z.infer<
  typeof jobDueDateMessageDropdownOptionSchema
>;

export const jobDueDateMessageDropdownOptionsSchema =
  jobDueDateMessageDropdownOptionSchema.array();
export type JobDueDateMessageDropdownOptions = z.infer<
  typeof jobDueDateMessageDropdownOptionsSchema
>;

export const jobDueDateMessageSchema = z.object({
  jobDueDateMessageId: z.number(),
  senderName: z.string(),
  message: z.string(),
  sentTime: z.string().transform(convertUTCTimestampStringToLocalDateTime),
});
export type JobDueDateMessage = z.infer<typeof jobDueDateMessageSchema>;

export const jobDueDateMessagesSchema = jobDueDateMessageSchema.array();
export type JobDueDateMessages = z.infer<typeof jobDueDateMessagesSchema>;

export const sendJobDueDateFreeformMessageSchema = z.object({
  message: z.string().min(1),
});
export type SendJobDueDateFreeformMessage = z.infer<
  typeof sendJobDueDateFreeformMessageSchema
>;

export const sendJobDueDatePreselectedMessageSchema = z.object({
  messageId: z.coerce.number().min(1),
});
export type SendJobDueDatePreselectedMessage = z.infer<
  typeof sendJobDueDatePreselectedMessageSchema
>;

export interface SendJobDueDateMessageErrors {
  errors: {
    Message: string[];
  };
}
