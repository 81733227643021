import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
import { Button, Card, Container, Nav, Row } from "react-bootstrap";
import { ArrowLeftCircleFill } from "react-bootstrap-icons";

export function JobDueDateLayout() {
  const { customerId, orderId, jobDueDateId } = useParams();
  const { search } = useLocation();

  return (
    <Container>
      <Row>
        <Link to={`/customer/${customerId}${search}`} className="mb-3">
          <Button variant="primary">
            <ArrowLeftCircleFill /> {" Back"}
          </Button>
        </Link>
      </Row>
      <Row>
        <Card>
          <Card.Header>
            <Nav variant="tabs">
              <Nav.Item>
                <NavLink
                  className="nav-link"
                  to={`/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/details${search}`}
                >
                  Details
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink
                  className="nav-link"
                  to={`/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/shipping${search}`}
                >
                  Shipping
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink
                  className="nav-link"
                  to={`/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/messaging${search}`}
                >
                  Messaging
                </NavLink>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body>
            <Outlet />
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}
