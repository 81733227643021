import { z } from "zod";

export const filterOptionSchema = z.object({
  id: z.number(),
  displayOrder: z.number(),
  displayValue: z.string(),
});

export type FilterOption = z.infer<typeof filterOptionSchema>;

export const filterOptionsSchema = filterOptionSchema.array();

export type FilterOptions = z.infer<typeof filterOptionsSchema>;
