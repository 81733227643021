import {
  ActionFunction,
  LoaderFunction,
  useActionData,
  useLoaderData,
  useParams,
  useSubmit,
} from "react-router-dom";
import { Form as FinalForm } from "react-final-form";
import {
  JobDueDateDetails,
  UpdateAskDateErrors,
  updateAskDateSchema,
} from "../../../../../../_types";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  formatDate,
  nameofFactory,
  UserRole,
  getUserRole,
} from "../../../../../../_helpers";
import { Save } from "react-bootstrap-icons";
import {
  DueDateQuantityCell,
  HeaderDetailItem,
} from "../../../../../../_components";
import { DatePicker } from "../../../../../../_components/_finalFormSpecific/Datepicker";
import {
  getJobDueDateDetails,
  updateAskDateForContact,
} from "../../../../../../_gateways/jobDueDateGateways";

export const jobDueDateDetailsLoader: LoaderFunction = async ({ params }) => {
  const userRole = await getUserRole();

  const { customerId, orderId, jobDueDateId } = params;
  if (!customerId || !orderId || !jobDueDateId)
    throw new Error("Cannot fetch data due to bad route.");

  switch (userRole as UserRole) {
    case UserRole.Admin:
    case UserRole.SalesPerson:
    case UserRole.Contact:
      return await getJobDueDateDetails({
        customerId,
        orderId,
        jobDueDateId,
      });
    default:
      throw new Error("Unauthorized.");
  }
};

export const updateAskDate: ActionFunction = async ({ params, request }) => {
  const userRole = await getUserRole();

  const { customerId, orderId, jobDueDateId } = params;
  if (!customerId || !orderId || !jobDueDateId)
    throw new Error(
      "Cannot update ask date due to bad route. Please try again."
    );

  const updatedAskDate = updateAskDateSchema.parse(
    Object.fromEntries(await request.formData())
  );

  // Determine which endpoint to call:
  switch (userRole as UserRole) {
    case UserRole.Contact:
      return await updateAskDateForContact({
        customerId,
        orderId,
        jobDueDateId,
        askDate: updatedAskDate.askDate,
      });
    default:
      throw new Error("Unauthorized.");
  }
};

export function JobDueDateDetailsPage() {
  const data = useLoaderData() as JobDueDateDetails;
  const nameof = nameofFactory<JobDueDateDetails>();
  const { customerId, orderId, jobDueDateId } = useParams();
  const submitUpdatedAskDate = useSubmit();
  const submitUpdatedAskDateErrors = useActionData() as UpdateAskDateErrors;

  const handleOnSubmit = async (values: Record<string, any>) => {
    submitUpdatedAskDate(values, {
      method: "put",
      action: `/customer/${customerId}/order/${orderId}/jobDueDate/${jobDueDateId}/details`,
    });
  };

  if (customerId == null || orderId == null || jobDueDateId == null) {
    return (
      <p>Invalid route. Please go back to the table overview and try again.</p>
    );
  }

  return (
    <FinalForm onSubmit={handleOnSubmit} initialValues={data}>
      {({ form, handleSubmit, submitting, pristine }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <HeaderDetailItem label="Customer Name" value={data.customerName} />
            <HeaderDetailItem
              label="Customer PO#"
              value={data.customerPONumber}
            />
            <HeaderDetailItem label="Buyer" value={data.contactName} />
            <HeaderDetailItem
              label="Part #"
              value={`${data.partNumber} - Rev: ${data.revision}`}
            />
            <Col xs={12} sm={4} lg={3} xl={2} xxl className="p-1">
              <div>
                <strong>Qty</strong>
              </div>
              <DueDateQuantityCell
                orderId={parseInt(orderId, 10)}
                jobDueDateId={parseInt(jobDueDateId, 10)}
                jobDueDateQuantity={data.dueDateQuantity}
              />
            </Col>
            <HeaderDetailItem
              label="Status"
              value={data.externalPortalStatusDisplay}
            />
            <HeaderDetailItem
              label="Original Due Date"
              value={
                data.originalDueDate !== null
                  ? formatDate(data.originalDueDate)
                  : ""
              }
            />

            {data.canEditAskDate ? (
              <Col xs={12} sm={4} lg={3} xl={2} xxl className="p-1">
                <DatePicker
                  name={nameof("askDate")}
                  label="Ask Date"
                  formGroupClassName="mb-2"
                  labelClassName="fw-bold"
                  serverError={submitUpdatedAskDateErrors?.errors?.AskDate}
                />
              </Col>
            ) : (
              <HeaderDetailItem
                label="Ask Date"
                value={data.askDate !== null ? formatDate(data.askDate) : ""}
              />
            )}

            <HeaderDetailItem
              label="Modified Due Date"
              value={
                data.modifiedDueDate !== null
                  ? formatDate(data.modifiedDueDate)
                  : ""
              }
            />
            <HeaderDetailItem
              label="At Risk?"
              value={data.atRisk ? "Y" : "N"}
            />
          </Row>

          {data.canEditAskDate && (
            <>
              <Button
                type="button"
                variant="secondary"
                onClick={() => form.reset()}
                disabled={submitting || pristine}
              >
                Cancel
              </Button>{" "}
              <Button
                type="submit"
                variant="primary"
                disabled={submitting || pristine}
              >
                {"Save "} <Save />
              </Button>
            </>
          )}
        </Form>
      )}
    </FinalForm>
  );
}
