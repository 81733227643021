import { z } from "zod";
import { transformDateOnlyString } from "../_helpers/dateTime";
import { filterOptionsSchema } from "./Filtering";

export const orderSchema = z.object({
  customerName: z.string(),
  customerPONumber: z.string().nullable(),
  orderId: z.number(),
  jobNumber: z.string().nullable(),
  jobId: z.number(),
  partNumber: z.string(),
  revision: z.string().nullable(),
  lowRezImage: z.string().nullable(),
  highRezImage: z.string().nullable(),
  jobDueDateId: z.number(),
  externalPortalStatusDisplay: z.string().nullable(),
  originalDueDate: z.string().nullable().transform(transformDateOnlyString),
  askDate: z.string().nullable().transform(transformDateOnlyString),
  modifiedDueDate: z.string().nullable().transform(transformDateOnlyString),
  dueDateQuantity: z.number(),
  atRisk: z.boolean(),
  contactName: z.string().nullable(),
});
export type Order = z.infer<typeof orderSchema>;

export const ordersSchema = orderSchema.array();
export type Orders = z.infer<typeof ordersSchema>;

export const ordersPagedSchema = z.object({
  total: z.number(),
  results: ordersSchema,
  canViewJobNumbers: z.boolean(),
});
export type OrdersPaged = z.infer<typeof ordersPagedSchema>;

export const ordersPageDataSchema = z.object({
  data: ordersPagedSchema,
  externalPortalStatusFilters: filterOptionsSchema,
});
export type OrdersPageData = z.infer<typeof ordersPageDataSchema>;
