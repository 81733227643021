import { z } from "zod";

const SORTDIRECTIONS = ["asc", "desc"] as const;

export const pagingParamsSchema = z.object({
  page: z.coerce.number().default(1),
  pageSize: z
    .union([
      z.literal("10"),
      z.literal("15"),
      z.literal("25"),
      z.literal("50"),
      z.literal("100"),
    ])
    .default("10")
    .transform((a) => parseInt(a, 10)), // there is probably a better way to do this type / parsing
});

export type PagingParams = z.infer<typeof pagingParamsSchema>;

export const sortingParamsSchema = z.object({
  sort: z.string().optional(),
  sortDirection: z.enum(SORTDIRECTIONS).default("asc"),
});

export type SortingParams = z.infer<typeof sortingParamsSchema>;

export interface FilteringParams {
  filterFields?: string[];
  filterValues?: string[];
}

export const orderFilterParamsSchema = z.object({
  includeClosed: z.coerce.boolean().optional(),
});
export type OrderFilterParams = z.infer<typeof orderFilterParamsSchema>;

export interface PagingSortingFilteringParams
  extends PagingParams,
    SortingParams,
    FilteringParams,
    OrderFilterParams {}
