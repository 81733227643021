import { Column } from "@tanstack/react-table";
import { Form } from "react-bootstrap";
import { FilterOption } from "../../_types";

export function DropdownFilter<T>({
  getFilterValue,
  setFilterValue,
  columnDef,
}: Column<T, unknown>) {
  return (
    <Form.Select
      onChange={(e) => setFilterValue(e.target.value)}
      value={(getFilterValue() ?? "") as string}
      size="sm"
    >
      <option value="" key="">
        {`Filter ${columnDef.header}`}
      </option>
      {columnDef.meta?.filterOptions?.map((filterOption: FilterOption) => (
        <option value={filterOption.displayValue} key={filterOption.id}>
          {filterOption.displayValue}
        </option>
      ))}
    </Form.Select>
  );
}
