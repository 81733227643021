import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

export const AppPage = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(`customer`, { replace: true });
  }, [navigate]);

  return <p>Redirecting to customer...</p>;
};
