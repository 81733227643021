import { LoaderFunction, useLoaderData } from "react-router-dom";
import { getJobDueDateShippingDetails } from "../../../../../../_gateways/jobDueDateGateways";
import {
  getUserRole,
  UserRole,
  nameofFactory,
  formatDate,
} from "../../../../../../_helpers";
import {
  JobDueDatePackingList,
  JobDueDatePackingLists,
} from "../../../../../../_types";
import React from "react";
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  BootstrapTable,
  TrackingNumberCell,
} from "../../../../../../_components";
import { PackingListId } from "./PackingListId";

export const jobDueDateShippingLoader: LoaderFunction = async ({ params }) => {
  const userRole = await getUserRole();

  const { customerId, orderId, jobDueDateId } = params;
  if (!customerId || !orderId || !jobDueDateId)
    throw new Error("Cannot fetch data due to bad route.");

  switch (userRole as UserRole) {
    case UserRole.Admin:
    case UserRole.SalesPerson:
    case UserRole.Contact:
      return await getJobDueDateShippingDetails({
        customerId,
        orderId,
        jobDueDateId,
      });
    default:
      throw new Error("Unauthorized.");
  }
};

export function JobDueDateShippingPage() {
  const data = useLoaderData() as JobDueDatePackingLists;
  const nameof = nameofFactory<JobDueDatePackingList>();

  const columnHelper = createColumnHelper<JobDueDatePackingList>();
  const columns = React.useMemo(() => {
    var generatedColumns = [
      columnHelper.accessor((row: JobDueDatePackingList) => row.packingListId, {
        header: "Packing List #",
        id: nameof("packingListId"),
        cell: (info) => <PackingListId id={info.getValue()} />,
      }),
      columnHelper.accessor((row: JobDueDatePackingList) => row.shipDate, {
        header: "Ship Date",
        id: nameof("shipDate"),
        cell: (info) => {
          const shipDate = info.getValue();
          return shipDate !== null && shipDate !== undefined
            ? formatDate(shipDate)
            : "";
        },
      }),
      columnHelper.accessor(
        (row: JobDueDatePackingList) => row.trackingNumber,
        {
          header: "Tracking Number(s)",
          id: nameof("trackingNumber"),
          cell: (info) => (
            <TrackingNumberCell trackingNumbers={info.getValue()} />
          ),
        }
      ),
      columnHelper.accessor(
        (row: JobDueDatePackingList) => row.packedQuantity,
        {
          header: "Packed Quantity",
          id: nameof("packedQuantity"),
        }
      ),
    ];

    return generatedColumns;
  }, [columnHelper, nameof]);

  const options: TableOptions<JobDueDatePackingList> = {
    columns,
    data: data,
    enableFilters: false,
    getCoreRowModel: getCoreRowModel(),
  };
  const table = useReactTable(options);

  if (data.length === 0) {
    return <div>No packing lists exist.</div>;
  }

  return (
    <>
      <BootstrapTable table={table} />
    </>
  );
}
